var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      attrs: {
        c: "",
        flat: "",
        tile: "",
        outline: "",
        color: "grey lighten-1 px-3"
      }
    },
    [
      _c("h2", { staticClass: "mb-3 secondary--text" }, [
        _vm._v(" " + _vm._s(_vm.$t(_vm.title)) + " ")
      ]),
      _c(
        "v-card-text",
        { staticClass: "pa-0" },
        [
          _c(
            "v-row",
            {
              staticClass:
                "d-flex justify-space-between align-center text-body-1 mb-2",
              attrs: { "no-gutters": "" }
            },
            [
              _c("strong", [
                _vm._v(_vm._s(_vm.$t("checkout.summary.itemsTotal")))
              ]),
              _c("strong", [
                _vm._v(" " + _vm._s(_vm.$n(_vm.netTotal, "currency")) + " ")
              ])
            ]
          ),
          _c(
            "v-row",
            {
              staticClass:
                "d-flex justify-space-between align-center text-body-2 mb-5",
              attrs: { "no-gutters": "" }
            },
            [
              _c("span", [
                _vm._v(
                  "(" +
                    _vm._s(_vm.$t("checkout.summary.itemsTotalByWeight")) +
                    ")"
                )
              ]),
              _c("span", [
                _vm._v(
                  _vm._s(_vm.$n(_vm.orderCart.totalPriceVariable, "currency"))
                )
              ])
            ]
          ),
          _vm.orderCart.deliveryFee > 0
            ? _c(
                "v-row",
                {
                  staticClass:
                    "d-flex justify-space-between align-center text-body-2 mb-2",
                  attrs: { "no-gutters": "" }
                },
                [
                  _c("span", [_vm._v(_vm._s(_vm.deliveryFeeTitle))]),
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.$n(_vm.orderCart.deliveryFee, "currency"))
                    )
                  ])
                ]
              )
            : _vm._e(),
          _vm.orderCart.taxTotal
            ? _c(
                "v-row",
                {
                  staticClass:
                    "d-flex justify-space-between align-center text-body-2",
                  attrs: { "no-gutters": "" }
                },
                [
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("checkout.summary.taxTotal")))
                  ]),
                  _c("span", [
                    _vm._v(_vm._s(_vm.$n(_vm.orderCart.taxTotal, "currency")))
                  ])
                ]
              )
            : _vm._e(),
          _vm.saved > 0
            ? _c(
                "v-row",
                {
                  staticClass:
                    "mt-4 text-body-1 font-italic primary--text font-weight-bold",
                  attrs: {
                    "no-gutters": "",
                    justify: "space-between",
                    align: "center"
                  }
                },
                [
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("checkout.summary.saving")))
                  ]),
                  _c("span", [_vm._v(_vm._s(_vm.$n(_vm.saved, "currency")))])
                ]
              )
            : _vm._e(),
          _c(
            "v-row",
            {
              staticClass: "d-flex justify-space-between align-center mt-6",
              attrs: { "no-gutters": "" }
            },
            [
              _c("h4", [_vm._v(_vm._s(_vm.$t("checkout.summary.total")))]),
              _c(
                "h3",
                [
                  _c("OrderGrossTotal", {
                    attrs: { item: _vm.orderCart, delivered: _vm.delivered }
                  })
                ],
                1
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }