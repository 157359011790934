var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("CartInfoCard", {
    attrs: {
      infoValue: _vm.value,
      text: _vm.text,
      hideEdit: true,
      icon: "$event",
      editable: _vm.editable,
      isCheckout: _vm.isCheckout
    },
    on: { edit: _vm.edit }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }